import Home from "@/pages/home.vue";
import User from "@/pages/user.vue";
import VueRouter from "vue-router";

const router = new VueRouter({
  mode: "history",
  routes: [
    { path: "/", redirect: "/home" },
    { path: "/home", component: Home },
    { path: "/user", component: User },
    {
      path: '/threed',
      component: (resolve) => require(['@/pages/three/threed'], resolve),
      name: 'Threed',
      meta: {title: '文物3D'},
      hidden: true,
    }
  ],
});

export default router;
